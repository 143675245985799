import React, { useState, useEffect } from "react";
import GMCDetailImage from "../../assets/img/gmc-details-image.jpg";
import GMCDetailGallery from "../../assets/img/rate-details-gallery.png";
import GMCRightFace from "../../assets/img/gmc-right-face.png";
import PerHour from "../../assets/img/per-hour-rate.svg";
import PerDay from "../../assets/img/per-day-rate.svg";
import AirportTransfer from "../../assets/img/airport-transfer.svg";
import RoundArrow from "../../assets/img/Arrow - Right Circle.svg";
import { Link, useParams } from "react-router-dom";

const ProductDetailContent = ({ carDetails, coverImage }) => {
  const [filteredImages, setFilteredImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');

  //   const [detailImages, setDetailImages] = useState([]);

  useEffect(() => {
    const filteredImages = carDetails?.imgurls?.filter((el) => el?.name?.includes('detailImages'));
    setFilteredImages(filteredImages);
    setSelectedImage(filteredImages?.[0].name);
  }, [carDetails]);

  const handleImageClick = (image) => {
      setSelectedImage(image);
  };

  return (
    <section id="rate-details-page"  style={{ paddingTop:"60px"}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="details-content">
              <div className="car-details-image">
                {/* <img src={`${process.env.REACT_APP_IMAGE_BASE_URL + selectedImage}`} alt="" style={{maxHeight:"430px", objectFit:"contain"}} /> */}
                <img src={`${selectedImage}`} alt="" style={{maxHeight:"430px", objectFit:"contain"}} />
              </div>
              <div className="row">
                {filteredImages?.map((el, i) => {
                  return (
                    <div className="col-lg-2 details-image-gallery">
                      <img
                        src={`${el?.name}`}
                        onClick={() => handleImageClick(el?.name)}
                        alt="car"
                      />
                      {/* <img
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL + el?.name}`}
                        onClick={() => handleImageClick(el?.name)}
                        alt="car"
                      /> */}
                    </div>
                  );
                })}
                {/* <img src={GMCDetailGallery} alt="car" /> */}
              </div>
              <div
                className="detail-text"
                dangerouslySetInnerHTML={{ __html: carDetails?.car_details }}
              />
              {/* <h3>{carDetails?.name}</h3> */}
              {/* <p>Denali signifies comfort and quality with attention to detail, including premium features and materials. Leather-appointed, heated driver and front-passenger seating, uncompromised cabin quietness, real aluminum trim, unique seat trims and French stitching is just a taste of the luxury of comfort and style you’ll get in a Denali.</p>
                    <p>From confident lines to signature styling cues, Denali will make a lasting impression wherever you go. Iconic GMC headlights and LED signatures light your way. Beautiful aluminum wheels and distinctive chrome accents demand a second look.
                    </p>
                    <p>Denali puts you in charge of the latest available technologies. You’ll experience your media like never before though the GMC IntelliLink1 infotainment system. Available 4G LTE W-Fi hotspot2 lets you stay connected on the go.`</p>
                    <h4>SERVICE OFFERED IN THIS VEHICLE</h4>
                    <ul className="car-inner-details">
                        <li>Corporate Transportation</li>
                        <li>Chauffeur Service</li>
                        <li>City Tours</li>
                        <li>Weddings & Events</li>
                        <li>Airport Transfers</li>
                    </ul> */}
              <h4>SERVICE OFFERED IN THIS VEHICLE</h4>
              <div className="youtube-video">
                <iframe
                  width="100%"
                  height="415"
                  src="https://www.youtube.com/embed/MBW26c8tvs4"
                  title="YouTube video player"
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="sidebar-details">
              <div className="sidebar-image">
                {/* <img src={`${process.env.REACT_APP_IMAGE_BASE_URL + coverImage}`} alt="" /> */}
                <img src={`${coverImage}`} alt="" />
              </div>
              <h3 className="pb-3 text-black">{carDetails?.name} </h3>
              <div className="row">
                <div className="col-lg-2 big-ic ">
                  <img  src={PerHour} alt="" />
                </div>
                <div className="col-lg-10">
                  <h5 className="mb-0" style={{fontSize:"17px", fontWeight:"700"}}>PER HOUR RATE</h5>
                  <h6 className="pb-0">
                    <span style={{fontSize:"24px", fontWeight:"700"}}>${carDetails?.rate}</span> / HOUR
                  </h6>
                  <p>*All inclusive - except driver</p>
                  <p>gratuity</p>
                  <p>2 hours minimum</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="seperator"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-2 big-ic">
                  <img src={PerDay} alt="" />
                </div>
                <div className="col-lg-10">
                  <h5 className="mb-0" style={{fontSize:"17px", fontWeight:"700"}} >PER DAY RATE</h5>
                  <h6 className="pb-0">
                    <span style={{fontSize:"24px", fontWeight:"700"}}>${carDetails?.rate * 8 - 160}</span> / DAY
                  </h6>
                  <p>8 hours of service</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="seperator"></div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-lg-2 big-ic">
                  <img src={AirportTransfer} alt="" />
                </div>
                <div className="col-lg-10">
                  <h5 className="mb-0" style={{fontSize:"17px", fontWeight:"700"}}>AIRPORT TRANSFER</h5>
                  <h6 className="pb-0">
                    <span style={{fontSize:"24px", fontWeight:"700"}}>CALL</span>
                  </h6>
                  <p>*price may vary per city</p>
                </div>
              </div>

              <div className="form-type-area">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-overlay">
                      <i className="fa-solid fa-car"></i>{" "}
                      {carDetails?.max_passenger} Passengers -{" "}
                      {carDetails?.max_bags} Luggages
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-overlay">
                      <i className="fa-solid fa-car"></i> Captain leather chairs
                      second-row
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-overlay">
                      <i className="fa-solid fa-car"></i> Power fold second and
                      third-row seat
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-overlay">
                      <i className="fa-solid fa-car"></i> Spacious luggage
                      compartment
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-overlay">
                      <i className="fa-solid fa-car"></i> Tinted privacy glass
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <Link
                      to={"/"}
                      className="btn btn-final-booking hvr-icon-forward"
                    >
                      Book Now{" "}
                      <img className="hvr-icon" src={RoundArrow} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-9">
                  <div className="details-content">
                    <div className="car-details-image">
                        <img src={GMCDetailImage} alt="" />
                    </div>
                    <div className="row">
                      <div className="col-lg-4">
                          <div className="details-image-gallery">
                              <img src={GMCDetailGallery} alt="" />
                          </div>
                      </div>
                    </div>
                    <h3>Chauffeur Service</h3>
                    <p>Denali signifies comfort and quality with attention to detail, including premium features and materials. Leather-appointed, heated driver and front-passenger seating, uncompromised cabin quietness, real aluminum trim, unique seat trims and French stitching is just a taste of the luxury of comfort and style you’ll get in a Denali.</p>
                    <p>From confident lines to signature styling cues, Denali will make a lasting impression wherever you go. Iconic GMC headlights and LED signatures light your way. Beautiful aluminum wheels and distinctive chrome accents demand a second look.
                    </p>
                    <p>Denali puts you in charge of the latest available technologies. You’ll experience your media like never before though the GMC IntelliLink1 infotainment system. Available 4G LTE W-Fi hotspot2 lets you stay connected on the go.`</p>
                    <h4>SERVICE OFFERED IN THIS VEHICLE</h4>
                    <ul className="car-inner-details">
                        <li>Corporate Transportation</li>
                        <li>Chauffeur Service</li>
                        <li>City Tours</li>
                        <li>Weddings & Events</li>
                        <li>Airport Transfers</li>
                    </ul>
                    <h4>SERVICE OFFERED IN THIS VEHICLE</h4>
                    <div className="youtube-video">
                      <iframe width="100%" height="415" src="https://www.youtube.com/embed/MBW26c8tvs4" title="YouTube video player" frameBorder="0" ></iframe>
                    </div>
                  </div>


              </div>
              <div className="col-lg-3">
                <div className="sidebar-details">
                    <div className="sidebar-image">
                        <img src={GMCRightFace} alt="" />
                    </div>
                    <h3>GMC Danali </h3>
                    <div className="row">
                        <div className="col-lg-2 big-ic">
                            <img src={PerHour} alt="" />
                        </div>
                        <div className="col-lg-10">
                            <h5>PER HOUR RATE</h5>
                            <h6><span>$140</span> / HOUR</h6>
                            <p>*All inclusive - except driver</p>
                            <p>gratuity</p>
                            <p>2 hours minimum</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="seperator"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 big-ic">
                            <img src={PerDay} alt="" />
                        </div>
                        <div className="col-lg-10">
                            <h5>PER DAY RATE</h5>
                            <h6><span>$960</span> / DAY</h6>
                            <p>8 hours of service</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="seperator"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 big-ic">
                            <img src={AirportTransfer} alt="" />
                        </div>
                        <div className="col-lg-10">
                            <h5>AIRPORT TRANSFER</h5>
                            <h6><span>CALL</span></h6>
                            <p>*price may vary per city</p>
                        </div>
                    </div>

                    <div className="form-type-area">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-overlay">
                                    <i className="fa-solid fa-car"></i> 6 Passengers - 6 Luggages
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-overlay">
                                    <i className="fa-solid fa-car"></i> Captain leather chairs second-row
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-overlay">
                                    <i className="fa-solid fa-car"></i> Power fold second and third-row seat
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-overlay">
                                    <i className="fa-solid fa-car"></i> Spacious luggage compartment
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-overlay">
                                    <i className="fa-solid fa-car"></i> Tinted privacy glass
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <Link to={'/'} className="btn btn-final-booking hvr-icon-forward">Book Now <img className="hvr-icon" src={RoundArrow} alt="" /></Link>
                            </div>
                        </div>
                    </div>
                        

                </div>
              </div> */}
        </div>
      </div>
    </section>
  );
};

export default ProductDetailContent;
