import React, { useEffect, useState } from "react";
import Plane from "../../assets/img/plane.svg";
import Driver from "../../assets/img/driver.svg";
import City from "../../assets/img/city.svg";
import EngagementRing from "../../assets/img/engagement-ring.svg";
import UserTie from "../../assets/img/user-tie-solid.svg";
import Umbrella from "../../assets/img/umbrella.svg";
import UserGroup from "../../assets/img/user-group.svg";
import VideoSolid from "../../assets/img/video-solid.svg";
import Keys from "../../assets/img/keys.svg";
import slugify from "react-slugify";

const Services = ({ mainTitle, subTitle }) => {
  const [data, setData] = useState();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/services/get`)
      .then((res) => res.json())
      .then((resData) => {
        setData(resData);
      });
  }, []);
  return (
    <>
      <section id="services-home">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-title">
                <h2>{mainTitle}</h2>
                <p>{subTitle}</p>
              </div>
            </div>
          </div>
          <div className="row">
            {data?.map((el, i) => {
              if (el.action === 1) {
                const icon = JSON.parse(el.urls).filter((el) =>
                  el.name.includes("icon")
                );
                return (
                  <div key={i} className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <div className="single-service">
                      <img
                        src={`${process.env.REACT_APP_IMAGE_BASE_URL + icon[0]?.name}`}
                        alt={icon}
                        width={60}
                        height={60}
                        style={{ objectFit: "contain" }}
                      />
                      <h4>{el.heading}</h4>
                      <p>{el.heading_two}</p>
                    </div>
                    <a href={slugify(el.title)}>
                      See More <i className="fa-solid fa-arrow-right-long"></i>
                    </a>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
