import React from 'react'

const ServicesHeader = () => {
  return (
    <section id="page-titles">
    <div className="service-page-overlay">

    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>Limousine Services</h2>
        </div>
      </div>
    </div>
  </section>
  )
}

export default ServicesHeader