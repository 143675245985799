import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import GMC from '../../assets/img/gmc.png'
import Cadillac from '../../assets/img/Cadillac.png'
import ChevySurburan from '../../assets/img/Chevy Surburban.png'
import RollsRoyce from '../../assets/img/Rolls Royce.png'

const Fleet = ({home_fleet_slider_image, mainTitle, subTitle}) => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows:false,
    responsive: [
      {
        breakpoint: 1024, // For screens less than 1024px
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600, // For screens less than 600px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500, // For screens less than 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const firstSlashIndex = subTitle.indexOf('/') + 1; // find position after the first '/'
  // const firstSlashIndex = originalText.indexOf('/') + 1; // find position after the first '/'
  
  // const firstPart = originalText.slice(0, firstSlashIndex).trim();  // "ALL"
  // const secondPart = originalText.slice(firstSlashIndex).trim();    // "/ SEDAN / SUV / SPECIALTY VEHICLES"
  let firstSlashIndex = ''
  let firstPart = ''
  let secondPart = ''

  if(subTitle && subTitle.length > 0) {
   // firstSlashIndex = subTitle.indexOf('/') + 1; // find position after the first '/'
    firstSlashIndex = subTitle.indexOf(' ') + 1;
    firstPart = subTitle.slice(0, firstSlashIndex).trim();  // "ALL"
    secondPart = subTitle.slice(firstSlashIndex).trim();    // "/ SEDAN / SUV / SPECIALTY VEHICLES"
  }




  return (



    <section id="fleet">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>{mainTitle}</h2>
          <p> <span className='subTitle-1'>{firstPart}</span> <span className='subTitle-2'>{secondPart}</span> </p>
          <Slider {...settings} id="owl-carousel-fleet" className="row">
            <div className="item col-lg-11">
                <div className=""><img src={GMC} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={Cadillac} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={ChevySurburan} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={RollsRoyce} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={GMC} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={Cadillac} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={ChevySurburan} alt="" /></div>
              </div>
              <div className="item col-lg-11">
                <div className=""><img src={RollsRoyce} alt="" /></div>
              </div>
        </Slider>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Fleet