import React from "react";
import FooterLogo from "../../assets/img/footer-logo.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Partner from "../../assets/img/partner.jpg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Footer = () => {
  const [footerData, setFooterData] = useState();
  const [footerLogo, setFooterLogo] = useState();

  const extractValues = (response, names) => {
    const extractedData = {};
    names.forEach((name) => {
      const item = response?.find((item) => item.field_name === name);
      extractedData[name] = item?.value || "";
    });
    return extractedData;
  };

  const getData = () => {
    const names = [
      "footer_logo",
      "footer_sub_title",
      "footer_second_column_title",
      "footer_second_column_image",
      "footer_second_column_sub_title",
      "footer_third_column_title",
      "footer_third_title_item_one",
      "footer_third_title_item_two",
      "footer_third_title_item_three",
      "footer_contact_number",
      "footer_book_ride",
      "footer_facebook",
      "footer_twitter",
      "footer_linkedin",
      "footer_youtube",
      "footer_instagram",
      "footer_pinterest",
      "footer_contact_email",
    ];
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/pages/6`)
      .then((res) => res.json())
      .then((resData) => {
        const extractedData = extractValues(resData.fields, names);
        setFooterData(extractedData);
        setFooterLogo(extractedData && extractedData?.footer_logo && JSON.parse(extractedData?.footer_logo)[0].name)
      });
  };

  useEffect(() => {
    if (!footerData) getData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <footer id="main-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <img src={`${process.env.REACT_APP_IMAGE_BASE_URL + footerLogo}`} alt="footer" />
            <div className="footer-logo-text">
              <p>{footerData?.footer_sub_title}</p>
            </div>
          </div>
          <div className="col-lg-3 padding-left-right">
            <h4>{footerData?.footer_second_column_title}</h4>
            <Slider {...settings}>
              <div className="item">
                <img src={Partner} alt="partner" />
              </div>
              <div className="item">
                <img src={Partner} alt="partner" />
              </div>
              <div className="item">
                <img src={Partner} alt="partner" />
              </div>
              <div className="item">
                <img src={Partner} alt="partner" />
              </div>
              <div className="item">
                <img src={Partner} alt="partner" />
              </div>
              <div className="item">
                <img src={Partner} alt="partner" />
              </div>
            </Slider>
            <p>{footerData?.footer_second_column_sub_title}</p>
          </div>
          <div className="col-lg-3">
            <h4>{footerData?.footer_third_column_title}</h4>
            <ul id="footer-nav">
              <li>
                <Link to={"/airport-service"}>
                  {footerData?.footer_third_title_item_one}
                </Link>
              </li>
              <li>
                <Link to={"/city-tours"}>
                  {footerData?.footer_third_title_item_two}
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  {footerData?.footer_third_title_item_three}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3">
            <h4>Contact Us</h4>
            <div className="row">
              <div className="col-lg-12">
                <span className="footer-telephone">
                  <i className="fa-solid fa-phone"></i>
                  <a href={`tel:${footerData?.footer_contact_number}`}>
                    {footerData?.footer_contact_number}
                  </a>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <span className="footer-email">
                  <i className="fa-solid fa-at"></i>
                  <a href={`mailto:${footerData?.footer_contact_email}`}>
                    {footerData?.footer_book_ride}
                  </a>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <ul id="social-icon">
                  <li>
                    <Link
                      to={footerData?.footer_facebook}
                      className="hvr-bounce-in"
                    >
                      <i className="fa-brands fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={footerData?.footer_twitter}
                      className="hvr-bounce-in"
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={footerData?.footer_linkedin}
                      className="hvr-bounce-in"
                    >
                      <i className="fa-brands fa-linkedin-in"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={footerData?.footer_youtube}
                      className="hvr-bounce-in"
                    >
                      <i className="fa-brands fa-youtube"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={footerData?.footer_instagram}
                      className="hvr-bounce-in"
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={footerData?.footer_pinterest}
                      className="hvr-bounce-in"
                    >
                      <i className="fa-brands fa-pinterest"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
