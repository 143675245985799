import React, { useEffect, useState } from "react";
import Plane from "../../assets/img/plane.svg";
import Driver from "../../assets/img/driver.svg";
import City from "../../assets/img/city.svg";
import EngagementRing from "../../assets/img/engagement-ring.svg";
import UserTie from "../../assets/img/user-tie-solid.svg";
import Umbrella from "../../assets/img/umbrella.svg";
import UserGroup from "../../assets/img/user-group.svg";
import VideoSolid from "../../assets/img/video-solid.svg";
import Keys from "../../assets/img/keys.svg";
import RightArrow from "../../assets/img/Arrow - Right Circle.svg";
import { Link } from "react-router-dom";
import slugify from "react-slugify";

const ServicesContent = () => {
  const [data, setData] = useState();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/services/get`)
      .then((res) => res.json())
      .then((resData) => {
        setData(resData);
      });
  }, []);
  return (
    <section id="services-page">
      <div className="container">
        <div className="row">
          {data?.map((el, i) => {
            if (el.action === 1) {
              const icon = JSON.parse(el.urls).filter((el) =>
                el.name.includes("icon")
              );
              return (
                <div className="col-lg-4" key={i}>
                  <div className="single-service">
                    <img
                      src={`${
                        process.env.REACT_APP_IMAGE_BASE_URL + icon[0].name
                      }`}
                      alt="icon"
                      width="50px"
                      
                    />
                    <div className="service-component">
                      <h4 style={{ height: "90px" }}>{el.heading}</h4>
                      <p>{el.heading_two}</p>
                    </div>
                  </div>
                  <a className="btn btn-service" href={slugify(el.title)}>
                    See More <img src={RightArrow} alt="" />
                  </a>
                </div>
              );
            }
          })}
          {/* <div className="col-lg-4">
          <div className="single-service">
            <img src={Plane} alt="" />
                <div className="service-component">
                    <h4>Airport Car Services</h4>
                    <p>We provide dependable, luxury car services to and from the airport. We’ll pick you up or drop you off at your home, hotel, office or airport. </p>
                </div>
            
          </div>
          <Link className="btn btn-service" to={'/airport-service'}>See More <img src={RightArrow} alt="" /></Link>
        </div>
        <div className="col-lg-4">
          <div className="single-service">
            <img src={Driver} alt="" />
            <div className="service-component">
                <h4>Chauffeur Service</h4>
                <p>Our chauffer is dedicated, discrete, courteous and professionally trained to accommodate youryour home, hotel, office or airport.</p>
            </div>
            
          </div>
          <Link className="btn btn-service" to={'/chauffeur-service'}>See More <img src={RightArrow} alt="" /></Link>
        </div>
        <div className="col-lg-4">
          <div className="single-service">
            <img src={City} alt="" />
            <div className="service-component">
                <h4>City Tours</h4>
                <p>If you’re looking to explore, we’re here for you. We know the best restaurants, points of attraction, your home, hotel, office or airport.</p>
            </div>
            
          </div>
          <Link className="btn btn-service" to={'/city-tours'}>See More <img src={RightArrow} alt="" /></Link>
        </div> */}
        </div>
        {/* <div className="row">
        <div className="col-lg-4">
          <div className="single-service">
            <img src={EngagementRing} alt="" />
            <div className="service-component">
                <h4>Weddings & Events</h4>
                <p>Go to and from your wedding or event in elegance and style. We’re here to make your...</p>
            </div>
            
          </div>
          <Link className="btn btn-service" to={'/'}>See More <img src={RightArrow} alt="" /></Link>
        </div>
        <div className="col-lg-4">
          <div className="single-service">
            <img src={UserTie} alt="" />
            <div className="service-component">
                <h4>Corp. 
                    Transportation</h4>
                <p>Call on us for all your corporate transportation needs. We offer top-notch professional car service...</p>
            </div>
            
          </div>
          <Link className="btn btn-service" to={'/'}>See More <img src={RightArrow} alt="" /></Link>
        </div>
        <div className="col-lg-4">
          <div className="single-service">
            <img src={Umbrella} alt="" />
            <div className="service-component">
                <h4>Leisure 
                    Transportation</h4>
                <p>Travel in style to Beverly Hills. Shop at the stores along Rodeo Drive. Have dinner at the renown...</p>
            </div>
            
          </div>
          <Link className="btn btn-service" to={'/'}>See More <img src={RightArrow} alt="" /></Link>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="single-service">
            <img src={UserGroup} alt="" />
            <div className="service-component">
                <h4>Group 
                    Transportation</h4>
                <p>Whether your group is traveling for business or pleasure, we’re here to help. Our professional...</p>
            </div>
            
          </div>
          <Link className="btn btn-service" to={'/'}>See More <img src={RightArrow} alt="" /></Link>
        </div>
        <div className="col-lg-4">
          <div className="single-service">
            <img src={VideoSolid} alt="" />
            <div className="service-component">
                <h4>Entertainment</h4>
                <p>Need a ride to attend the Grammys or the Oscars™ or car service to your production set? Want to...</p>
            </div>
            
          </div>
          <Link className="btn btn-service" to={'/'}>See More <img src={RightArrow} alt="" /></Link>
        </div>
        <div className="col-lg-4">
          <div className="single-service">
            <img src={Keys} alt="" />
            <div className="service-component">
                <h4>Valet Service</h4>
                <p>Planning a large event and need a valet to assist your guests? We can help you with your valet service...</p>
            </div>
            
          </div>
          <Link className="btn btn-service" to={'/'}>See More <img src={RightArrow} alt="" /></Link>
        </div>
      </div> */}
      </div>
    </section>
  );
};

export default ServicesContent;
