import React from 'react'
import { Link } from 'react-router-dom';


const CallToAction = ({bgImage, mainTitle, subTitle}) => {
  const bannerImage = bgImage && JSON.parse(bgImage)[0].name
  const dynamicBackgroundStyle = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL + bannerImage})`
  };
  return (
    <section id="call-to-action" style={dynamicBackgroundStyle}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <h2>{mainTitle}</h2>
          <p>{subTitle}</p>
       
          <Link  to={`${process.env.REACT_APP_BASE_URL}/home`} target="_blank" className="btn btn-main hvr-icon-forward" role="button">Book Now 
          
          {/* <img alt="icon" className="hvr-icon"  
           src={require("../../assets/img/Arrow-Right-Circle.svg")}  /> */}
          <img alt="icon" className="hvr-icon"  
           src={require("../../assets/img/Arrow-Right-Circle.svg").default}  />
           
           </Link>
        </div>
      </div>
    </div>
  </section>
  )
}

export default CallToAction