import React from "react";
import AirportServiceVector from "../../assets/img/airport-service.svg";
import AirportServicesImage from "../../assets/img/Airport Services 1.png";

const PagesContent = ({
  heading,
  content,
  contentImage,
  pageIcon,
  iconAltText,
  pageImageAltText,
}) => {
  const lines = heading.split('\n');
  const lastWordFirstLine = lines[lines.length - 1];
  const firstWordNextLine = lines[lines.length];

  return (
    <section id="service-inner">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 service-content">
            <div className="row">
              <div className="col-lg-5">
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL + pageIcon}`}
                  alt={iconAltText}
                />
                <h4>{lastWordFirstLine} {firstWordNextLine}</h4>
              </div>
            </div>
            <div className="col-lg-6 d-block d-md-none">
            <img className="pb-34 mt-0 service-md-img"
              src={`${process.env.REACT_APP_IMAGE_BASE_URL + contentImage}`}
              alt={pageImageAltText}
            />
          </div>
          
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {/* <p>Transport your employees and business partners in style and comfort to meetings, events, lunches, dinners, the airport or accommodations with private car service. Our courteous and professional executive chauffeur has extensive knowledge of the Los Angeles, Orange County, San Diego and Santa Barbara areas to quickly get you, your employees and business partners to your destination on time, every time.
                </p>
                <p>Sit back and relax in the luxurious comfort of your private limo service. Take call, complete last minute tasks, or even hold a private meeting during your ride. 
                </p>
                <p>When you choose Limousine 4 Hire, you gain private limo service with a knowledgeable executive chauffeur and comfortable transportation. Additionally, as your private, executive car service, we take on the liabilities of your company’s travelers for stress-free ground travel.</p>
                <p>Our online scheduler makes booking your next ride with Limousine 4 Hire fast and easy. Book your ride today.</p> */}
            <a className="btn btn-booknow hvr-icon-forward" href="rates">
              Book Now
            </a>
          </div>
          <div className="col-lg-6">
            <img className="d-none d-md-block"
              src={`${process.env.REACT_APP_IMAGE_BASE_URL + contentImage}`}
              alt={pageImageAltText}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PagesContent;
