import React from 'react'
import { useState, useEffect } from 'react';

const ServiceAgain = () => {
  const [data, setData] = useState()

  const extractValues = (response, names) => {
    const extractedData = {};
    names.forEach(name => {
      const item = response?.find(item => item.field_name === name);
      extractedData[name] = item?.value || "";
    });
    return extractedData;
  };

  useEffect(() => {
    const names = ["service_again_title", "service_again_subtitle"];
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/pages/1`)
      .then(res => res.json())
      .then(resData => {
        const extractedData = extractValues(resData.fields, names);
        setData(extractedData);
      });
  }, []);

  return (
    <section id="footer-showcase">
    <div className="pre-footer-bg"></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>{data?.service_again_title}</h2>
          <h5>{data?.service_again_subtitle}</h5>
        </div>
      </div>
    </div>
  </section>
  )
}

export default ServiceAgain