import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";
import { useState } from "react";

const Testimonial = (props) => {
  const [data, setData] = useState([]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const dynamicBackgroundOne = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL + props?.sliderOneImage?.[0]?.name})`,
  };
  const dynamicBackgroundTwo = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL + props?.sliderTwoImage?.[0]?.name})`,
  };
  const dynamicBackgroundThree = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL + props?.sliderThreeImage?.[0]?.name})`,
  };
  const dynamicBackgroundFour = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL + props?.sliderFourImage?.[0]?.name})`,
  };

  return (
    <section id="testimonial">
      <Slider {...settings}>
        <div className="item">
          <div className="section-relative">
            <div className="section-bg-1 testimonial-image" style={dynamicBackgroundOne}></div>
            <div className="container padding-bottom-huge">
              <div className="row">
                <div className="col-lg-6 111">
                  <div className="row">
                    <div className="col-lg-12 222">
                      <h2 className="sliderOneTitle">{props.sliderOneTitle}</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p>{props.sliderOneDescription}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="section-relative">
            <div className="section-bg-2 testimonial-image" style={dynamicBackgroundTwo}></div>
            <div className="container padding-bottom-huge">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2>{props.sliderTwoTitle}</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p>{props.sliderTwoDescription}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="section-relative">
            <div className="section-bg-3 testimonial-image" style={dynamicBackgroundThree}></div>
            <div className="container padding-bottom-huge">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2>{props.sliderThreeTitle}</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p>{props.sliderThreeDescription}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="section-relative">
            <div className="section-bg-4 testimonial-image" style={dynamicBackgroundFour}></div>
            <div className="container padding-bottom-huge">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2>{props.sliderFourTitle}</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <p>{props.sliderFourDescription}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6"></div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default Testimonial;
