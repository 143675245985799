import React from "react";
import Header from "../components/Shared/Header";
import Footer from "../components/Shared/Footer";
import ServiceAgain from "../components/Shared/ServiceAgain";
import ContactHeader from "../components/Contact/ContactHeader";
import ContactForm from "../components/Contact/ContactForm";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";

export const Contact = ({ seoData }) => {
  const [data, setData] = useState();
  const [metaData, setMetaData] = useState();

  const extractValues = (response, names) => {
    const extractedData = {};
    names.forEach((name) => {
      const item = response?.find((item) => item.field_name === name);
      extractedData[name] = item?.value || "";
    });
    return extractedData;
  };

  useEffect(() => {
    const names = [
      "contact_title",
      "contact_description_para_1",
      "contact_description_para_2",
      "contact_banner_bg_image",
      "contact_detail_title",
      "contact_detail_subtitle",
      "contact_number",
      "contact_number_landline",
      "contact_email",
      "dispatch_service_timings",
      "sales_timings",
      "social_media_title",
      "contact_form_title",
      "facebook_link",
      "twitter_link",
      "linkedin_link",
      "youtube_link",
      "instagram_link",
      "pinterest_link",
    ];
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/pages/5`)
      .then((res) => res.json())
      .then((resData) => {
        const filterData = resData?.fields?.filter(el=>el.action == 1)
        setMetaData(resData);
        const extractedData = extractValues(filterData, names);
        setData(extractedData);
      });
  }, []);

  return (
    <div>
      <Helmet>
        <meta name="meta_robot" content={metaData?.meta_robot} />
        <meta name="description" content={metaData?.meta_description} />
        <script type="text/json">{metaData?.global_structure_markup}</script>
        <link rel="page_url" href={metaData?.page_url} />
        <title>{data?.contact_title}</title>
        <script type="text/json">{seoData && seoData?.google_analytics}</script>
        <script type="text/json">
          {seoData && seoData?.global_structure_markup}
        </script>
        <meta
          name="google_site_verification"
          content={seoData.site_verification_meta_tag}
        />
      </Helmet>
      <Header />
      <ContactHeader
        mainTitle={data?.contact_title}
        subTitleParaOne={data?.contact_description_para_1}
        subTitleParaTwo={data?.contact_description_para_2}
        bgImage={data && data?.contact_banner_bg_image && JSON.parse(data?.contact_banner_bg_image)}
      />
      <ContactForm
        mainTitle={data?.contact_detail_title}
        subTitle={data?.contact_detail_subtitle}
        number={data?.contact_number}
        landline={data?.contact_number_landline}
        email={data?.contact_email}
        serviceTiming={data?.dispatch_service_timings}
        salesTiming={data?.sales_timings}
        socialMediaTitle={data?.social_media_title}
        contactFormTitle={data?.contact_form_title}
        facebook={data?.facebook_link}
        twitter={data?.twitter_link}
        linkedin={data?.linkedin_link}
        youtube={data?.youtube_link}
        instagram={data?.instagram_link}
        pinterest={data?.pinterest_link}
      />
      <ServiceAgain />
      <Footer />
    </div>
  );
};
