import React, { useEffect, useState } from "react";
import ProductDetailImage from "../../assets/img/gmc-description.png";

const BlogDetailHeader = () => {
  const [backgroundImage, setBackgroundImage] = useState();

  const extractValues = (response, names) => {
    const extractedData = {};
    names.forEach((name) => {
      const item = response?.find((item) => item.field_name === name);
      extractedData[name] = item?.value || "";
    });
    return extractedData;
  };

  useEffect(() => {
    const names = ["blog_bg_image"];
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/pages/4`)
      .then((res) => res.json())
      .then((resData) => {
        const extractedData = extractValues(resData.fields, names);
        setBackgroundImage(
          extractedData && extractedData?.blog_bg_image && JSON.parse(extractedData?.blog_bg_image)[0]?.name
        );
      });
  }, []);

  const dynamicBackgroundStyle = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL + backgroundImage})`,
  };

  return (
    <section id="page-titles">
      <div className="blog-detail-overlay" style={dynamicBackgroundStyle}></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <h2>Blog</h2>
          </div>
          <div className="col-lg-4 offset-lg-4">
            <div className="title-content">
              {/* <img src={`http://localhost:8080/${coverImage}`} alt="car" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailHeader;
