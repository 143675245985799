import React, { useEffect, useState } from 'react'

const BlogHeader = () => {
  const [data, setData] = useState();

  const extractValues = (response, names) => {
    const extractedData = {};
    names.forEach((name) => {
      const item = response?.find((item) => item.field_name === name);
      extractedData[name] = item?.value || "";
    });
    return extractedData;
  };

  useEffect(() => {
    const names = [
      "blog_title",
      "blog_bg_image",
    ];
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/pages/4`)
      .then((res) => res.json())
      .then((resData) => {
        const extractedData = extractValues(resData.fields, names);
        setData(extractedData);
      });
  }, []);
  const dynamicBackgroundStyle = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL + (data && data?.blog_bg_image && JSON.parse(data?.blog_bg_image)?.[0]?.name)})`
  };
  
  return (
    <section id="page-titles">
    <div className="airport-service-overlay" style={dynamicBackgroundStyle}>

    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2>{data?.blog_title}</h2>
        </div>
      </div>
    </div>
  </section>
  )
}

export default BlogHeader