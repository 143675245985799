import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const BannerArea = ({
  mainTitle,
  subTitle,
  cardMainTitle,
  cardTitle1,
  cardContent1,
  cardTitle2,
  cardContent2,
  cardTitle3,
  cardContent3,
  cardEditButton,
  cardDeleteButton,
  bannerBg
}) => {
  const bannerImage = bannerBg && JSON.parse(bannerBg)[0].name
  const dynamicBackgroundStyle = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL + bannerImage})`
  };
  return (
    <section id="banner-area">
      <div className="overlay" style={dynamicBackgroundStyle}></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="huge-margin">{mainTitle}</h2>
            <span className="elegant">
              <i className="fa-solid fa-square-check"></i>
              {subTitle}
            </span>
            <h2 className="right-align-h">24/7</h2>
          </div>
          <div className="col-lg-6">
            <h3>{cardMainTitle}</h3>
            <div className="call-action-group hvr-bounce-in first-box">
              <div className="row">
                <div className="col-lg-12">
                  <h4>
                    <i className="fa-solid fa-plane"></i>
                    {cardTitle1}
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">{cardContent1}</div>
                <div className="col-lg-4">
                  <Link
                    to={`${process.env.REACT_APP_BASE_URL}/home`} target="_blank"
                    className="btn btn-main hvr-icon-forward"
                    role="button"
                  >
                    Book Now{" "}
                   
                    <img alt="icon" className="hvr-icon"  
           src={require("../../assets/img/Arrow-Right-Circle.svg").default}  />
                  </Link>
                </div>
              </div>
            </div>
            <div className="call-action-group hvr-bounce-in">
              <div className="row">
                <div className="col-lg-12">
                  <h4>
                    <i className="fa-solid fa-city"></i>
                    {cardTitle2}
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">{cardContent2}</div>
                <div className="col-lg-4">
                  <Link
                    to={`${process.env.REACT_APP_BASE_URL}/home`} target="_blank"
                    className="btn btn-main hvr-icon-forward"
                    role="button"
                  >
                    Book Now{" "}

                    <img alt="icon" className="hvr-icon"  
           src={require("../../assets/img/Arrow-Right-Circle.svg").default}  />
                  </Link>
                </div>
              </div>
            </div>
            <div className="call-action-group hvr-bounce-in">
              <div className="row">
                <div className="col-lg-12">
                  <h4>
                    <i className="fa-solid fa-clock"></i>
                    {cardTitle3}
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">{cardContent3}</div>
                <div className="col-lg-4">
                  <Link
                   to={`${process.env.REACT_APP_BASE_URL}/home`} target="_blank"
                    className="btn btn-main hvr-icon-forward"
                    role="button"
                  >
                    Book Now{" "}
                    
                    <img alt="icon" className="hvr-icon"  
           src={require("../../assets/img/Arrow-Right-Circle.svg").default}  />
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <Link to={`${process.env.REACT_APP_BASE_URL}/history`} target="_blank" className="btn btn-edit hvr-icon-pulse">
                  {cardEditButton}<i className="fa-solid fa-pen hvr-icon"></i>
                </Link>
              </div>
              <div className="col-lg-6">
                <Link to={`${process.env.REACT_APP_BASE_URL}/history`} target="_blank" className="btn btn-cancel hvr-icon-pulse">
                  {cardDeleteButton}
                  <i className="fa-solid fa-xmark hvr-icon"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerArea;
