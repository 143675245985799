import React from 'react'

const ContactHeader = ({mainTitle, subTitleParaOne, subTitleParaTwo, bgImage}) => {
  const dynamicBackgroundStyle = {
    backgroundImage: `url(${process.env.REACT_APP_IMAGE_BASE_URL + bgImage?.[0]?.name})`
  };
  return (
    <section id="page-titles">
    <div className="airport-service-overlay" style={dynamicBackgroundStyle}>

    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h2 id="contact-h">{mainTitle}</h2>
          <div className="col-lg-5">
            <div className="contact-overlay">
              <p>{subTitleParaOne}</p>
              <p>{subTitleParaTwo}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default ContactHeader