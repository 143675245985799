import React, { useEffect, useState } from "react";
import Header from "../components/Shared/Header";
import Footer from "../components/Shared/Footer";
import ServiceAgain from "../components/Shared/ServiceAgain";
import RatesHeader from "../components/Rates/RatesHeader";
import RatesContent from "../components/Rates/RatesContent";
import { Helmet } from "react-helmet";

export const Rates = ({ seoData }) => {
  const [data, setData] = useState();
  const [metaData, setMetaData] = useState();

  const extractValues = (response, names) => {
    const extractedData = {};
    names.forEach((name) => {
      const item = response?.find((item) => item.field_name === name);
      extractedData[name] = item?.value || "";
    });
    return extractedData;
  };

  useEffect(() => {
    const names = ["rates_title", "rates_bg_image", "rates_fg_image"];
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/pages/3`)
      .then((res) => res.json())
      .then((resData) => {
        const filterData = resData?.fields?.filter(el=>el.action == 1)
        setMetaData(resData);
        const extractedData = extractValues(filterData, names);
        setData(extractedData);
      });
  }, []);

  return (
    <div>
      <Helmet>
        <meta name="meta_robot" content={metaData?.meta_robot} />
        <meta name="description" content={metaData?.meta_description} />
        <script type="text/json">{metaData?.global_structure_markup}</script>
        <link rel="page_url" href={metaData?.page_url} />
        <title>{data?.rates_title}</title>
         <script type="text/json">{seoData && seoData?.google_analytics}</script>
         <script type="text/json">{seoData && seoData?.global_structure_markup}</script>
        <meta
          name="google_site_verification"
          content={seoData.site_verification_meta_tag}
        />
      </Helmet>
      <Header />
      <RatesHeader
        mainTitle={data?.rates_title}
        backgroundImage={data && data?.rates_bg_image && JSON.parse(data?.rates_bg_image)}
        foregroundImage={data && data?.rates_fg_image && JSON.parse(data?.rates_fg_image)}
      />
      <RatesContent />
      <ServiceAgain />
      <Footer />
    </div>
  );
};
