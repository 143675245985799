import React, { useEffect, useState } from "react";
import User from "../../assets/img/User.png";
import RightArrow from "../../assets/img/Arrow - Right Circle.svg";
import BlogImage from "../../assets/img/Dummy Blog Images 1.png";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import "./posts.css";

const Posts = () => {
  const [data, setData] = useState([]);
  const [blogId, setBlogId] = useState();
  const [commentsCount, setCommentsCount] = useState({});
  const [numberOfComments, setNumberOfComments] = useState();
  const navigate = useNavigate([]);
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = data.filter((item) => item.action === 1);

  const itemsPerPage = 6;

  const totalPages = Math.ceil(filteredData?.length / itemsPerPage);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  const currentValues = filteredData?.slice(firstIndex, lastIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/blogs/get`)
      .then((res) => res.json())
      .then((resData) => {
        setData(resData);
        setBlogId(resData.id);
        resData.forEach((blog) => {
          fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/blogs/${blog.id}`)
            .then((res) => res.json())
            .then((commentsData) => {
              const commentsWithAction1 =
                commentsData?.comments?.filter((el) => el.action === "1") || [];
              setCommentsCount((prevCounts) => ({
                ...prevCounts,
                [blog.id]: commentsWithAction1.length,
              }));
            });
        });
      });
  }, []);

  const handleReadMore = (id) => {
    navigate(`/blog-detail/${id}`);
  };

  return (
    <section id="blog-content-area">
      <div className="container">
        <div className="row">
          {currentValues
            ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            ?.map((el, index) => {
              const coverImage = el?.urls ? JSON.parse(el?.urls)[0]?.name : "";
              const tagsArr = JSON.parse(el?.tags);
              const date = new Date(el.createdAt);
              const formattedDate = `${date.getUTCDate()} ${date.toLocaleString(
                "en-US",
                { month: "short" }
              )} ${date.getUTCFullYear()}`;
              if (el.action == 1) {
                if (index == 0 || (index + 1) % 6 == 0) {
                  return (
                    <React.Fragment key={index}>
                      <div className="col-lg-8">
                        <div className="blog-image">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_BASE_URL + coverImage}`}
                            alt={el.alt_text}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="blog-meta">
                          <div className="blog-author">
                            <img src={User} alt="icon" /> By Limosine 4 hire
                          </div>
                          <div className="blog-date">
                            <i className="fa-solid fa-clock"></i>
                            {formattedDate}
                          </div>
                        </div>
                        <div className="blog-title">
                          <h3>{el.title}</h3>
                        </div>
                        <div className="blog-article">
                          <p>{el.short_description}</p>
                        </div>
                        <div className="blog-category">
                          <i
                            className="fa-regular fa-folder-open"
                            style={{ paddingRight: "7px" }}
                          ></i>
                          {tagsArr?.map((val, i) => (
                            <>{val.label}, </>
                          ))}
                          <br />
                          <i
                            className="fa-solid fa-comment-dots"
                            style={{ paddingRight: "7px" }}
                          ></i>{" "}
                          By B&W Limo{" "}
                          <i
                            className="fa-solid fa-comment-dots"
                            style={{ paddingRight: "7px", paddingLeft: "7px" }}
                          ></i>{" "}
                          {commentsCount[el.id] || 0} Comment
                        </div>
                        <a
                          onClick={() => handleReadMore(el.id)}
                          className="btn btn-blog-readmore hvr-icon-forward"
                          role="button"
                        >
                          Read More{" "}
                          <img
                            className="hvr-icon"
                            src={RightArrow}
                            alt="icon"
                          />
                        </a>
                      </div>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={index}>
                      <div className="col-lg-6">
                        <div className="blog-image">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_BASE_URL + coverImage}`}
                            alt={el.alt_text} style={{objectFit: "contain"}}
                          />
                        </div>
                        <div className="blog-meta">
                          <div className="blog-author">
                            <img src={User} alt="icon" /> By Limosine 4 hire
                          </div>
                          <div className="blog-date">
                            <i className="fa-solid fa-clock"></i>
                            {formattedDate}
                          </div>
                        </div>
                        <div className="blog-title">
                          <h3>{el.title}</h3>
                        </div>
                        <div className="blog-article">
                          <p>{el.short_description}</p>
                        </div>
                        <div className="blog-category">
                          <i
                            className="fa-regular fa-folder-open"
                            style={{ paddingRight: "7px" }}
                          ></i>
                          {tagsArr?.map((val, i) => (
                            <>{val.label}, </>
                          ))}
                          <br />{" "}
                          <i
                            className="fa-solid fa-comment-dots"
                            style={{ paddingRight: "7px" }}
                          ></i>{" "}
                          By B&W Limo
                          <i
                            className="fa-solid fa-comment-dots"
                            style={{ paddingRight: "7px", paddingLeft: "7px" }}
                          ></i>{" "}
                          {commentsCount[el.id] || 0} Comment
                        </div>
                        <a
                          onClick={() => handleReadMore(el.id)}
                          className="btn btn-blog-readmore hvr-icon-forward"
                          role="button"
                        >
                          Read More{" "}
                          <img
                            className="hvr-icon"
                            src={RightArrow}
                            alt="icon"
                          />
                        </a>
                      </div>
                    </React.Fragment>
                  );
                }
              }
            })}

          <Pagination>
            <Pagination.Prev
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
               <FaArrowLeft /> {/* Custom icon for Previous */}
            </Pagination.Prev>
            {Array.from({ length: totalPages })?.map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {" "}
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
               <FaArrowRight /> {/* Custom icon for Next */}
            </Pagination.Next>
          </Pagination>
        </div>
      </div>
    </section>
  );
};

export default Posts;
